<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";


export default {
  page: {
    title: "Option d'assurance",
    meta: [{ name: "description", content: appConfig.description }],
    data: {
      type: Array,
      required: true,
    },
  },
  components: {
    Layout,
    PageHeader,



  },
  data() {
    return {
      title: "Formules",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Formules",
          active: true,
        },
      ],
  
    
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      codePromo: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "admin", sortable: true, label: "Administrateur" },
        { key: "option", sortable: true, label: "Option" },
        { key: "montant", sortable: true, label: "Montant" },
      
        { key: "dateCreation", sortable: true, label: "Date création" },
     
        { key: "action", sortable: true, label: "Action" },
      ],
      abonnements: [],
      labels: [
        {
          nom: { title: "Nom et Prénom" },
          trajet: { title: "Trajets" },
          options: { title: "Options" },
          montant: { title: "Montant" },
          date: { title: "Date" },
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      erreur: false,
      yearSelected: { name: "2022", value: 2022 },
      yearvalue: 2022,
      defaultMonth: null,
      defaultChoice:{id:null, value:""},
      showModalOption:false,

      option:{
        id:"",
        option:"",
        montant:0
      }
    

     
    
      

    };
  },
  validations: {},

  mounted() {
    this.init();


  },
  methods: {
    async init() {
      const listAssurance = await apiRequest(
        "GET",
        "assurer",
        undefined,
        false
      );

      if (listAssurance && listAssurance.data) {
        this.totalRows = listAssurance.data.totalAssurance;
        const assuranceListe = listAssurance.data.map((assurance) => {
          return {
            id: assurance.id,
            admin: `${assurance.idAdmin.firstname} ${assurance.idAdmin.lastname}`,
            option: assurance.option,
           
            dateCreation: new Date(assurance.dateCreation).toLocaleString(
              "fr-FR"
            ),
            montant: assurance.montant,
          };
        });
        this.abonnements = assuranceListe;
      }
    },
  
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleSuccess(msg) {
      console.log("good ", msg);
    },
    handleError(err) {
      console.log("error ", err);
    },
  
    deleteRow(row) {
      this.showDelete = true;
      this.codePromoRowInfo = row;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileUploadModif() {
      this.fileModif = this.$refs.files.files[0];
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },


    editClick(item){
    this.showModalOption=true;  
    this.option.option=item.option
    this.option.id=item.id
    this.option.montant=item.montant

    },


 

    async changeOption() {
      this.showModalChangeTel = false;
      const edit = await apiRequest(
        "PUT",
        "assurer/" + this.option.id,
        {
          id: this.option.id,
          option: this.option.option,
          montant: this.option.montant,
        },
        false
      );

      if (edit && edit.data) {
        this.showModalOption=false; 
 
        this.$toast.success(`L'option a été modifiée avec succès`, {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});






        this.init();
      } else {
        this.$toast.error(`La modification de loption a échouée`, {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

      }
    },






  },

  
  watch: {
    yearvalue: {
      handler(val) {
        console.log("l'année sélectionnée est :", val);
        this.yearvalue;
      },
    },

    defaultMonth: {
      handler(val) {
        console.log(Number(val.id));


        if (val.id != "" && val.id) {
         this.defaultChoice.id=val.id;
         this.defaultChoice.value=val.value
          }
        }
      },
    

          /*  
     let  date =Number(item.date_assurance.split(" ")[0].split('/')[1])
         console.log(date);
            return date===(Number(val.id)); 
   
        }


      }
      */
    
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
  

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Lsite des formules</a
                    >
                  </template>
                  <div
                    class="row d-flex justify-content-between align-items-center mt-4"
                  >
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    
                    <div class="inputCol p-3">
                      <div class="input">
                        <input
                          type="search"
                          id="serchInput"
                          placeholder="Rechercher..."
                          v-model="filter"
                        />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :fields="fields"
                      :items="abonnements"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                    </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            :rows="abonnements"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="erreur"
      title="Veuillez remplir toutes les options."
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false"
          >Ok</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ce code promo ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletepromo"
          >Oui</b-button
        >
      </div>
    </b-modal>

 
    <b-modal
      v-model="showModalOption"
      title="Changer les informations"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Option"
            v-model="option.option"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Montant"
            v-model="option.montant"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeTel = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changeOption">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
  </Layout>
</template>
<style scoped>
.imageCard {
  display: flex;
  align-items: center;
  flex-direction: column;
}

thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}
thead th:first-child {
  font-weight: 600 !important;
}
thead th span {
  color: #d6d2f8;
}

.nav-tabs .nav-link {
   background: #dc143c;
   transition: all .5s;
 } 
 .nav-tabs .nav-link a{
  color: #fff ;
}
 .text-red{
  color:#dc143c;
}  .nav-tabs .nav-link a{
  color: #fff!important ;
}

.text-green{
  color:#3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff!important;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35!important;
  border-bottom: 1px solid #000000!important;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000!important;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

#chart {
  position: relative;
  margin-top: 40px;
  width: 500px;
  height: 200px;
}
@-webkit-keyframes bargrowth {
  0% {
    max-height: 0%;
  }
  100% {
    max-height: 100%;
  }
}
@keyframes bargrowth {
  0% {
    max-height: 0%;
  }
  100% {
    max-height: 100%;
  }
}
.bar {
  -webkit-animation: bargrowth 1000ms ease;
  animation: bargrowth 1000ms ease;
  position: absolute;
  bottom: 0;
  display: inline-block;
  background: #89b800;
  box-shadow: 1px -1px #79a300, 2px -2px #79a300, 3px -3px #79a300,
    4px -4px #79a300, 5px -5px #79a300, 6px -6px #79a300, 7px -7px #79a300,
    8px -8px #79a300, 9px -9px #79a300, 10px -10px #79a300;
}

.wrapper {
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  width: 500px;
  font-family: Helvetica;
  font-size: 12px;
}
.wrapper > h1,
.wrapper > p {
  margin: 0;
}

.button__custom {
  color: var(--gray-0);
  background-color: #37b24d;
  height: 2.3rem;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  margin-bottom: 15px;
}

.exportFlex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 400px;
}

.multiselect__tags {
  border: 3px solid #37b24d !important;
  color: #37b24d !important;
}
.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}




.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}


</style>
